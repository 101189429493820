import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function createData(
  id: string,
  destination: string,
  line: number,
  departure: string,
  status: string,
  track: string,
  backcolor: string,
  forecolor: string,
  history: { [key: string]: number }[],
  total_track_hits: number
) {
  return {
    id,
    destination,
    line,
    departure,
    status,
    track,
    backcolor,
    forecolor,
    history,
    total_track_hits
  };
}

function formatTimeTo12Hour(timeString: string) {
  const [hours, minutes] = timeString.split(":");
  let formattedHours = parseInt(hours, 10);
  let period = "AM";

  if (formattedHours === 0) {
    formattedHours = 12;
  } else if (formattedHours === 12) {
    period = "PM";
  } else if (formattedHours >= 12) {
    formattedHours = formattedHours % 12;
    period = "PM";
  }

  return `${formattedHours}:${minutes} ${period}`;
}

  function formatStringWithEmoji(input: string): string {
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(input, 'text/html').body.textContent || '';
    const formatted = decodedString.replace(/&#(\d+);/g, (_, match) => String.fromCodePoint(parseInt(match)));
    return formatted;
  }

  function Row(props: { row: ReturnType<typeof createData> }) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const historyArray = Object.entries(row.history).map(([key, value]) => ({ key, value }));
    const sortedHistory = historyArray.sort((a, b) => Number(b.value) - Number(a.value));
  
    let backgroundColor;
    switch (row.backcolor) {
      case "#009CDB":
        backgroundColor = "#93B5C6";
        break;
      case "#F7505E":
        backgroundColor = "#BD4F6C";
        break;
      case "#FF993E":
        backgroundColor = "#D7816A";
        break;
      case "#C36366":
        backgroundColor = "#F0CF65";
        break;
      case "#00953B":
        backgroundColor = "#DDEDAA";
        break;
      default:
        backgroundColor = row.backcolor;
        break;
    }
    const timeString = row.departure.split("T")[1].split(":").slice(0, 2).join(":");
    const formattedTime = formatTimeTo12Hour(timeString);

    return (
      <React.Fragment>
        <TableRow sx={{
          '&:not(:last-child)': {
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
            marginBottom: '20px',
          },
          '&:first-of-type': {
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
          },
          backgroundColor: backgroundColor,
        }} style={{ backgroundColor: backgroundColor }}>
          <TableCell component="th" scope="row" sx={{ fontSize: '16px' }}>
            <strong>
              {row.id}
            </strong>
          </TableCell>
          <TableCell align="left" sx={{ fontSize: '16px' }}><strong>{row.destination}</strong></TableCell>
          <TableCell align="left" sx={{ fontSize: '16px' }}><strong>{row.line}</strong></TableCell>
          <TableCell align="left" sx={{ fontSize: '16px' }}><strong>{formattedTime}</strong></TableCell>
          <TableCell align="left" sx={{ fontSize: '16px' }}><strong>{row.track}</strong></TableCell>
          <TableCell sx={{ fontSize: '16px' }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div" fontWeight="bold">
                  History
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontSize: '16px' }}><strong>Track</strong></TableCell>
                      <TableCell align="right" sx={{ fontSize: '16px' }}><strong>Total hits</strong></TableCell>
                      <TableCell align="right" sx={{ fontSize: '16px' }}><strong>Probability</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedHistory.map(({ key, value }) => (
                      <TableRow key={key}>
                        <TableCell component="th" scope="row">
                          {key}
                        </TableCell>
                        <TableCell align="right" sx={{ fontSize: '16px' }}>{value.toString()}</TableCell>
                        <TableCell align="right" sx={{ fontSize: '16px' }}>{(Math.round(Number(value) / row.total_track_hits * 100)).toString() + "%"}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

export default function CollapsibleTable() {
    const [rows, setRows] = useState<ReturnType<typeof createData>[]>([]);
    useEffect(() => {
        fetch('https://www.track.choochootracker.com/')
        .then((response) => response.json())
        .then((data) => {
            let formattedRows = []
            let track = ""
            let schedule = data["schedule"]
            for (var i in schedule) {
                let total_hits = 0
                if (schedule[i].track == "") {
                  if(schedule[i].status == " ") {
                    track = "PENDING"
                  } else {
                    track = schedule[i].status
                  }
                } else {
                    track = schedule[i].track
                }
                Object.entries(schedule[i].track_history).forEach(([key, value]) => {
                    total_hits += schedule[i].track_history[key]
                })
                const emojistring = formatStringWithEmoji(schedule[i].destination)

                formattedRows.push(createData(schedule[i].id, emojistring, schedule[i].line, schedule[i].departure, schedule[i].status, track, schedule[i].backcolor, schedule[i].forecolor, schedule[i].track_history, total_hits))
            }
            formattedRows.sort((a, b) => {
              const dateA = new Date(a.departure);
              const dateB = new Date(b.departure);
              return dateA.getTime() - dateB.getTime();
            });
            setRows(formattedRows);
          })
        .catch((error) => {
            console.error('Error fetching data:', error);
        });
        }, []);
        
        return (
          <Box sx={{ display: 'flex', justifyContent: 'center', backgroundColor: '#212121'}}>
            <TableContainer component={Paper} sx={{ maxWidth: '1600px', margin: 'auto' }}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow sx={{backgroundColor: '#212120', border: '1px solid #fff'}} >
                    <TableCell style={{ fontSize: '20px', color: 'white' }} ><strong>Train ID</strong></TableCell>
                    <TableCell style={{ fontSize: '20px', color: 'white' }} align="left"><strong>Train</strong></TableCell>
                    <TableCell style={{ fontSize: '20px', color: 'white' }} align="left"><strong>Line</strong></TableCell>
                    <TableCell style={{ fontSize: '20px', color: 'white' }} align="left"><strong>Departure Time</strong></TableCell>
                    <TableCell style={{ fontSize: '20px', color: 'white' }} align="left"><strong>Track</strong></TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                {rows.map((row) => (
                    <Row key={row.id} row={row} />
                ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          );        
}
