import * as React from 'react';
import List from '@mui/material/List';
import { mainListItems } from './components/listItems';
import CollapsibleTable from "./components/table";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
import { Box } from '@mui/material';

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#212121', 
    },
    secondary: {
      main: '#212121', 
    },
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function App() {
  const [fontSize, setFontSize] = React.useState(24);

  React.useEffect(() => {
    document.body.style.backgroundColor = '#212121'; 

    const handleResize = () => {
      const windowWidth = window.innerWidth;
      const newFontSize = Math.floor(windowWidth / 40); 

      setFontSize(newFontSize);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="absolute" >
        <Toolbar>
          <Typography
            component="h1"
            variant="h2"
            color="inherit"
            noWrap
            sx={{
              flexGrow: 1,
              fontSize: {
                xs: '16px',
                sm: `${fontSize}px`,
              },
            }}
          >
            Choo Choo Tracker - NY Penn
          </Typography>
          <List component="nav">
            {mainListItems}
          </List>
        </Toolbar>
        <Box
          sx={{
            backgroundColor: '#FFF'
          }}
        >
          <CollapsibleTable />
        </Box>
        <Box sx={{ marginTop: '40px', marginBottom: '40px' }}> 
        </Box>
      </AppBar>
    </ThemeProvider>
  );
}
